import React from 'react'
import { Avatar, Box, Grid, Paper, Stack, Typography } from '@mui/material'
import Lucie from '../../../../assets/Lucie.png'
import { FaCircleCheck } from 'react-icons/fa6'

import Logo01 from './01.png'
import Logo02 from './02.png'
import Logo03 from './03.png'
import Logo04 from './04.png'

export default function Banktype() {
    return (
        <>
            <Box py={10} >
                <Stack direction="row" spacing={2} alignItems='center'>
                    <Avatar alt="Lucie" src={Lucie} />
                    <Typography variant='h4'>Lucie</Typography>
                </Stack>
                <Typography variant='h3' sx={{ fontWeight: '500', py: 4 }}>Auprès de quelle banque avez-vous souscrit ce prêt immobilier ? <br />
                    Il est vraiment important que vous répondiez correctement à cette question car <br /> les exigences en matière de garanties changent d'une banque à l'autre.</Typography>
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                            <Paper elevation={3} sx={{ position: 'relative', py: 4, px: 2, textAlign: 'center', borderRadius: '10px' }} >
                                <Box sx={{ position: 'absolute', top: '15px', right: '15px' }}>
                                    <FaCircleCheck style={{ fontSize: '25px', color: '#27DAA6' }} />
                                </Box>
                                <Box component='img' src={Logo01} alt='logo' sx={{ width: '100%', height: 'auto' }} />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={3}>
                             <Paper elevation={3} sx={{ position: 'relative', py: 4, px: 2, textAlign: 'center', borderRadius: '10px' }} >
                                <Box sx={{ position: 'absolute', top: '15px', right: '15px' }}>
                                    <FaCircleCheck style={{ fontSize: '25px', color: '#27DAA6' }} />
                                </Box>
                                <Box component='img' src={Logo02} alt='logo' sx={{ width: '100%', height: 'auto' }} />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={3}>
                             <Paper elevation={3} sx={{ position: 'relative', py: 4, px: 2, textAlign: 'center', borderRadius: '10px' }} >
                                <Box sx={{ position: 'absolute', top: '15px', right: '15px' }}>
                                    <FaCircleCheck style={{ fontSize: '25px', color: '#27DAA6' }} />
                                </Box>
                                <Box component='img' src={Logo03} alt='logo' sx={{ width: '100%', height: 'auto' }} />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={3}>
                             <Paper elevation={3} sx={{ position: 'relative', py: 4, px: 2, textAlign: 'center', borderRadius: '10px' }} >
                                
                                <Box component='img' src={Logo04} alt='logo' sx={{ width: '100%', height: 'auto' }} />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={3}>
                             <Paper elevation={3} sx={{ position: 'relative', py: 4, px: 2, textAlign: 'center', borderRadius: '10px' }} >
                                
                                <Box component='img' src={Logo02} alt='logo' sx={{ width: '100%', height: 'auto' }} />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={3}>
                             <Paper elevation={3} sx={{ position: 'relative', py: 4, px: 2, textAlign: 'center', borderRadius: '10px' }} >
                                
                                <Box component='img' src={Logo03} alt='logo' sx={{ width: '100%', height: 'auto' }} />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={3}>
                             <Paper elevation={3} sx={{ position: 'relative', py: 4, px: 2, textAlign: 'center', borderRadius: '10px' }} >
                               
                                <Box component='img' src={Logo04} alt='logo' sx={{ width: '100%', height: 'auto' }} />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Paper elevation={3} sx={{ position: 'relative', py: 4, px: 2, textAlign: 'center', borderRadius: '10px' }} >
                             
                                <Box component='img' src={Logo01} alt='logo' sx={{ width: '100%', height: 'auto' }} />
                            </Paper>
                        </Grid>

                    </Grid>

                </Box>
            </Box>
        </>
    )
}
