import React from 'react'

import { Box, Container, Grid, styled, Typography } from "@mui/material";
import HeroBg from './HeroBg.png'
import { ReactComponent as Perso } from "./perso.svg";
import { ReactComponent as Family } from "./Icons/Family.svg";
import { ReactComponent as OldMan } from "./Icons/OldMan.svg";
import { ReactComponent as House } from "./Icons/House.svg";
import { ReactComponent as Car } from "./Icons/car.svg";
import { ReactComponent as Dove } from "./Icons/dove.svg";
import { ReactComponent as Pet } from "./Icons/pet.svg";

import { Link } from 'react-router-dom';


export default function HeroMain() {
    const BoxLone = {
        borderRadius: '20px',
        background: ' #FFF',
        boxShadow: ' 2px 3px 6px 0px #066598',
        maxWidth: '210px',
        height: '150px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '15px',
        textAlign: 'center',
        gap: 1,
        textDecoration: 'none',
        color: '#37474F',
        "&:hover": {
            background: "#066598",
            color: '#ffffff'
        },
    }

    const CustomBox = styled(Box)(({ theme }) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: theme.spacing(5),
        height: '100%',
        [theme.breakpoints.down("md")]: {
            paddingTop: '2rem',
            alignItems: "start",
        },
    }));

    const AvecData = [
        {
            Icons: <Family />,
            Name: "Mutuelle santé famille",
            LinkTo: '/',
        },
        {
            Icons: <OldMan />,
            Name: "Mutuelle santé senior",
            LinkTo: '/',
        },
        {
            Icons: <House />,
            Name: "Assurance prêt immobilier",
            LinkTo: '/Real_Estate',
        },
        {
            Icons: <Car />,
            Name: "Assurance automobile",
            LinkTo: '/',
        },
        {
            Icons: <Pet />,
            Name: "Assurance chien et chat",
            LinkTo: '/',
        },
        {
            Icons: <Dove />,
            Name: "Assurance obsèque",
            LinkTo: '/',
        },
    ]

    return (

        <Box className="flexcenter" sx={{
            position: 'relative',
            pt: 8,
            height: '100vh',
            maxHeight: '900px',
            flexShrink: 0,
            backgroundImage: `url(${HeroBg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% 100%',
        }}
        >
            <Container maxWidth="xl" sx={{ height: '100%' }}>
                <CustomBox>

                    <Box sx={{ flex: "1" }}>
                        <Typography sx={{
                            color: '#ffffff',
                            fontWeight: '700',
                            textAlign: { xs: 'left', sm: 'left' },
                            fontSize: {
                                xs: '23px',
                                sm: '25px',
                                md: '30px',
                                lg: '45px'
                            },
                            lineHeight: '1.2',
                            marginBottom: '1rem'
                        }}>
                            Avec Swis, comparez les <br /> meilleures offres.
                        </Typography>
                        <Box sx={{ mt: { xs: 2, sm: 4 } }}>
                            <Grid container rowSpacing={2} columnSpacing={{ xs: 2, sm: 0, md: 0 }} sx={{ maxWidth: '690px' }}>
                                {
                                    AvecData.map((Items, index) => {
                                        return (
                                            <Grid item xs={6} md={4} key={index}>
                                                <Box component={Link} to={Items.LinkTo} sx={{ ...BoxLone }}>
                                                    {Items.Icons}
                                                    <Typography sx={{
                                                        fontSize: {
                                                            lg: '1.125rem',
                                                            md: '1.125rem',
                                                            sm: '1.125rem',
                                                            xs: 15
                                                        }, color: 'inherit', fontWeight: '700'
                                                    }}>{Items.Name}</Typography>
                                                </Box>
                                            </Grid>
                                        )
                                    })
                                }

                            </Grid>
                        </Box>
                    </Box>
                    <Box sx={{ display: { xs: 'none', sm: 'flex' }, justifyContent: 'end', alignItems: 'end', height: '100%' }}  >
                        <Perso style={{ width: '100%' }} />
                    </Box>
                </CustomBox>
            </Container>
        </Box>

    )
}
