import React from 'react'
import {  Container } from '@mui/material' 
import PartnerSlider from './PartnerSlider'
export default function OurPartnerMain() {
  return (
    <Container maxWidth="xl" sx={{py:4, height: '100%', textAlign:'center' }}> 
      <PartnerSlider />
    </Container>

  )
}
