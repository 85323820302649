import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { FaCheck } from "react-icons/fa6";

import { BsInfoCircle } from 'react-icons/bs'
import { AiOutlineClose } from "react-icons/ai";

export default function PermanentTotalDisability() {
    const NeSontData = [
        "Les accidents liés à la consommation d'alcool ou de stupéfiants",
        "L’invalidité résultant d’une tentative de suicide ou de mutilations volontaires",
        "Les accidents résultant de vols aériens non autorisés",
        "L’invalidité suite à la participation volontaire à une émeute ou un acte de terrorisme",
        "La conduite en état d'ivresse",
        "L’invalidité causée par une pathologie connue mais non déclarée dans votre questionnaire de santé",
        "Les arrêts de travail suite à la participation volontaire à une émeute ou un acte de terrorisme",
        "L’invalidité liée à un congé paternité/maternité",
        "L’invalidité résultant de la pratique amateur d'un sport dangereux (sauf si elle est exceptionnelle et/ou encadrée par un professionnel)",
        "L’invalidité liée à des troubles psychiatriques (sauf s'ils entraînent une hospitalisation de plus de 10 jours consécutifs)",
        "L’invalidité liée à des problèmes de dos (sauf s'ils entraînent une hospitalisation de plus de 10 jours consécutifs)",
         
    ]
    return (
        <>
            <Box mt={2}>
                <Typography variant='h6' color='#96948a'>Sont notamment inclus :</Typography>
                <Stack direction="row" spacing={1.5} alignItems="center" sx={{ mt: 1 }}>
                    <FaCheck style={{ color: '#32deac', fontSize: '20px' }} />
                    <Typography variant='h5'>Les accidents ou maladies occasionnant un taux d'invalidité supérieur ou égal à 66%</Typography>
                </Stack>
            </Box>
            <Box sx={{ mt: 3 }}>
                <Typography variant='h6' color='#96948a'>Ne sont pas inclus :</Typography>
                {
                    NeSontData.map((Items, Index) => {
                        return (
                            <Stack direction="row" spacing={1.5} alignItems="center" key={Index} sx={{ my: 1 }}>
                                <AiOutlineClose style={{ color: '#D95762', fontSize: '20px' }} />
                                <Typography variant='h5'>{Items}</Typography>
                            </Stack>
                        )
                    })
                }

            </Box>
            <Stack direction="row" spacing={1} alignItems='star' justifyContent='left' sx={{ mt: 4, pr: 5, backgroundColor: '#51f8c024', p: 2, borderRadius: '5px' }}>
                <Box sx={{ mt: '2px !important' }}>
                    <BsInfoCircle style={{ color: '#555554' }} />
                </Box>
                <Typography variant='body2' sx={{ textAlign: 'left', color: '#555554', fontSize: '15px', fontWeight: '400' }}>Ne sont pas garanties les arrêts de travail correspondant à la durée légale du congé de paternité, défini à l’article L.1225-35 du Code du travail, que l'assuré soit salarié ou non</Typography>
            </Stack>
        </>
    )
}
