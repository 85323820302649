import React from "react";
 
import { Box, Card, CardActionArea, CardContent, Container, Grid, Typography, } from "@mui/material";
import { ReactComponent as Shuttle } from "./Shuttle.svg";
import { ReactComponent as File } from "./file.svg";
import { ReactComponent as Trans } from "./Trans.svg";
import { ReactComponent as Clock } from "./Clock.svg";
import { ReactComponent as Phone } from "./Phone.svg";
import { ReactComponent as CardIcon } from "./card.svg";
import { ReactComponent as People } from "./People.svg";

export default function LesAvantagesMain() { 

    const AvezList = [
        {
            heading: 'Rembousement rapide',
            Icons: Shuttle,
        },
        {
            heading: 'Documentation simple',
            Icons: File,
        },
        {
            heading: 'Transparence des frais',
            Icons: Trans,
        },
        {
            heading: 'Réponse instantanée',
            Icons: Clock,
        },
        {
            heading: 'Toutes vos informations sur votre mobile',
            Icons: Phone,
        },
        {
            heading: 'Remboursement en ligne',
            Icons: CardIcon,
        },
        {
            heading: 'Une équipe à votre écoute',
            Icons: People,
        },

    ]
    return (
        <Box pb={7}>
            <Container maxWidth="lg" sx={{height: '100%' }}>

                <Box my='{md:8rem,xs: 3rem,}'>
                    <Box textAlign='center' my='5rem' >
                        <Typography variant='h1' sx={{
                            mb: 2, color: '#37474F', fontWeight: '900', fontSize: {
                                xs: '23px',
                                sm: '25px',
                                md: '30px',
                                lg: '35px'
                            },
                        }}>
                           Les avantages de <span style={{ color: '#7EDBC0' }}>Swis Assurance</span>
                        </Typography>
                    </Box>
                    <Grid container spacing={2} justifyContent="center"
                        alignItems="center">
                        {AvezList.map((menu) => (
                            <Grid item xs={6} md={3} key={menu.heading}>
                                <Card sx={{ border: '1px solid #eee', maxWidth: "250px", width: '100%', display: "flex", textAlign: 'center', boxShadow: '0px 4px 4px 0px #7EDBC0', borderRadius: '10px' }} >
                                    <CardActionArea sx={{ py: { xs: 2, lg: 2, xl: 3 } }}>

                                        <CardContent>
                                            <menu.Icons style={{ width: '100px', height: '100px', marginBottom: '15px' }} />
                                            <Typography variant="h5" color="text.lightdark" >
                                                {menu.heading}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        ))}

                    </Grid>
                </Box>
            </Container>
        </Box>
    )
}
