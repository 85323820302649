import React from 'react'
import { RiFacebookFill } from 'react-icons/ri'
import { AiOutlineInstagram, AiOutlineTwitter, AiTwotonePhone } from 'react-icons/ai'
import { TiSocialLinkedin } from 'react-icons/ti'
import { Box, Container,  InputBase, Paper, Stack, Typography } from "@mui/material";


import { Link } from 'react-router-dom';

import { ReactComponent as LogoWhite } from '../logoWhite.svg'

import { PiMapPinFill } from 'react-icons/pi'
 
export default function Footer() {
    const footerNav = [
        {
            id: 1,
            title: "Nos offres",
            links: [
                {
                    name: 'Mutuelle santé',
                    link: "/",
                    target: ''
                },
                {
                    name: 'Mutuelle santé senior',
                    link: "/",
                    target: '_blank'
                },
                {
                    name: 'Assurance prêt immobilier',
                    link: "/a-propos",
                    target: ''
                },
                {
                    name: 'Assurance obsèque',
                    link: "/",
                    target: '_blank'
                },
                {
                    name: 'Assurance automobile',
                    link: "/a-propos",
                    target: ''
                },
                {
                    name: 'Assurance chiens/chats',
                    link: "/",
                    target: '_blank'
                },

            ]
        },
    ];




    const IconStyle = {
        width: '30px',
        height: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '100px',
        color: "#00156a",
        backgroundColor: "#9af9e3",
        '&:hover': {
            backgroundColor: '#0066ee'
        }
    };
    return (
        <>
            <Box
                sx={{
                    backgroundColor: "#455A64",
                    pb: 3,
                    pt: {
                        xs: 4,
                        md: 6,
                        lg: 8,
                    },
                }}
            >
                <Container maxWidth="xl" >
                    <Stack direction={{sm:'column', md: 'row'}} spacing={4} justifyContent='space-between'>
                        <Box>
                            <LogoWhite />
                            <Typography variant='h6' sx={{ mt: 1, color: '#ffffff', lineHeight: '20px' }}>
                                1Swis Expert en <br /> Assurance Santé Senior
                            </Typography>
                            <Box mt={5}>
                                <Typography variant='h6' sx={{ color: '#ffffff', mb: 1 }}>
                                    Suivez-nous</Typography>
                                <Stack direction="row" spacing={2}>
                                    <Link href="" style={IconStyle}> <RiFacebookFill size={20} /></Link>
                                    <Link href="" style={IconStyle}> <AiOutlineInstagram size={20} /></Link>
                                    <Link href="" style={IconStyle}> <AiOutlineTwitter size={20} /></Link>
                                    <Link href="" style={IconStyle}> <TiSocialLinkedin size={20} /></Link>
                                </Stack>
                            </Box>
                        </Box>
                        <Box>
                            {footerNav.map((item, index) => (
                                <Box key={index}>
                                    <Typography variant='h5' sx={{ color: '#ffffff', fontWeight: '700', mb: 2 }}>
                                        {item.title}
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: 0.5,
                                        }}
                                    >
                                        {item.links.map((datas, is) => (
                                            <Link
                                                key={is}
                                                className="flink"
                                                to={datas.link}
                                                target={datas.target}
                                            >
                                                {datas.name}
                                            </Link>
                                        ))}
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                        <Box>
                            <Typography variant='h5' sx={{ color: '#ffffff', fontWeight: '700', mb: 3 }}>Contactez-nous</Typography>

                            <Box component={Link} to='tel:0184200060' sx={{ color: '#9af9e3', textDecoration: 'none', }}>
                                <Stack direction="row" spacing={1} justifyContent='start' alignItems='center'>
                                    <AiTwotonePhone fontSize={25}/>
                                    <Typography variant='h6' sx={{ fontWeight: '400', color: '#9af9e3', }}> 01 84 20 00 60</Typography>
                                </Stack>
                            </Box>
                            <Box component={Link} to='https://www.google.fr/maps/place/1Swis+Expert+en+Assurance+Sant%C3%A9+Senior/@48.8502323,2.297074,17z/data=!3m1!4b1!4m5!3m4!1s0x47e671b8cb6e76f9:0x49d9b6ec91f48f80!8m2!3d48.8502288!4d2.2992627' sx={{ display: 'block', mt: 3, color: '#9af9e3', textDecoration: 'none', }} target='_blank'>
                                <Stack direction="row" spacing={1} justifyContent='start' alignItems='center'>
                                    <PiMapPinFill fontSize={25} />
                                    <Typography variant='h6' sx={{ fontWeight: '400', color: '#9af9e3', }}>
                                        54 Av. de la Motte-Picquet,  <br />
                                        75015 Paris, France</Typography>
                                </Stack>
                            </Box>
                        </Box>
                        <Box>
                            <Typography variant='h5' sx={{ color: '#ffffff', fontWeight: '700', mb: 3 }}>Inscrivez-vous à notre newsletter</Typography>
                            <Paper component="form"
                                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 330 }}>
                                <InputBase
                                    sx={{ ml: 1, flex: 1 }}
                                    placeholder="Entrez votre e-mail"
                                    inputProps={{ 'aria-label': 'search google maps' }}
                                />
                                <Box color="primary" sx={{ p: '7px 20px', fontSize: '15px', color: '#455A64', background: '#9AF9E3', cursor: 'pointer' }} aria-label="directions">
                                    M’inscrire
                                </Box>
                            </Paper>
                        </Box>
                    </Stack>

                    <Typography sx={{ textAlign: 'center', fontSize: '15px', fontWeight: '400', color: '#ffffff', mt: 7 }}>
                        1Swis - Copyright 2023 All rights reserved.
                    </Typography>
                </Container>
            </Box>




        </>
    )
}
