import React from 'react'
import PropertyFinancedLoan from './PropertyFinancedLoan/PropertyFinancedLoan'
import WantToDoType from './WantToDoType/WantToDoType'
import CurrentlyCoversLons from './CurrentlyCovers/CurrentlyCoversLons'
import MainResidence from './MainResidence/MainResidence'
import { Box } from '@mui/material'

export default function InformationsDuBienMain() {
    return (
        <>
            <Box sx={{px: {xs:'0', sm: 15}}}>
                <WantToDoType />
                <CurrentlyCoversLons />
                <PropertyFinancedLoan />
                <MainResidence />
            </Box>
        </>
    )
}
