import React from 'react'
import { Avatar, Box, FormControl, FormLabel, Grid, InputAdornment,  OutlinedInput,  Stack, Typography } from '@mui/material'
import Lucie from '../../../../assets/Lucie.png'
import { BsInfoCircle } from 'react-icons/bs' 

export default function CurrentInsuranceMain() {
    return (
        <Box pt={10} >
            <Stack direction="row" spacing={2} alignItems='center'>
                <Avatar alt="Lucie" src={Lucie} />
                <Typography variant='h4'>Lucie</Typography>
            </Stack>
            <Typography variant='h3' sx={{ fontWeight: '500', py: 4 }}>Parfait, merci ! Faisons mieux connaissance. Si nous nous connaissons déjà, vous pouvez vous connecter ici.</Typography>

            <Box>

                <Box sx={{ mb: 2.5 }}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={6}>
                            <FormControl sx={{ textAlign: 'left' }} fullWidth>
                                <FormLabel sx={{ fontWeight: '500', color: '#000', mb: 1 }}>Prénom*</FormLabel>
                                <OutlinedInput
                                    size="small"
                                    fullWidth
                                    placeholder="50"
                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                    type="number"
                                    sx={{
                                        backgroundColor: '#fff'
                                    }}
                                />
                            </FormControl>
                            <Stack direction="row" spacing={1} alignItems='star' justifyContent='left' sx={{ mt: 1.2, pr: 5 }}>
                                <Box sx={{ mt: '4px !important' }}>
                                    <BsInfoCircle style={{ color: '#96948a' }} />
                                </Box>
                                <Typography variant='body2' sx={{ textAlign: 'left', color: '#96948a', fontSize: '15px', fontWeight: '400' }}>Swis prendra en charge 40% du prêt en cas de décès ou d'accident vous empêchant de payer vos mensualités.</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl sx={{ textAlign: 'left' }} fullWidth>
                                <FormLabel sx={{ fontWeight: '500', color: '#000', mb: 1 }}>Nom*</FormLabel>
                                <OutlinedInput
                                    size="small"
                                    fullWidth
                                    placeholder="50"
                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                    type="number"
                                    sx={{
                                        backgroundColor: '#fff'
                                    }}
                                />
                            </FormControl>
                            <Stack direction="row" spacing={1} alignItems='star' justifyContent='left' sx={{ mt: 1.2, pr: 5 }}>
                                <Box sx={{ mt: '4px !important' }}>
                                    <BsInfoCircle style={{ color: '#96948a' }} />
                                </Box>
                                <Typography variant='body2' sx={{ textAlign: 'left', color: '#96948a', fontSize: '15px', fontWeight: '400' }}>Swis prendra en charge 80% du prêt en cas de décès ou d'accident empêchant manash de payer vos mensualités.</Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>

            </Box>
        </Box>

    )
}
