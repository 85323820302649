import * as React from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box'; 
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';

import { AiOutlineMenuFold } from 'react-icons/ai'
import { Button, Container } from '@mui/material';
import { Link } from 'react-router-dom';

import logo from '../logoWhite.svg'
import Navbar from '../component/Navbar';

const ContButton = {
  color: '#ffffff',
  backgroundColor: '#066598',
  borderRadius: '35px',
  padding: '7px 25px',
  boxShadow: ' 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  fontSize: '20px',
  fontWeight: 900,
}

const drawerWidth = '100%';

export default function Layout({ children }) {

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center', mt: 4 }}>
 
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2,  }}>
        <Navbar />
        <Button variant="contained" sx={{ ...ContButton }}>Contactez-nous</Button>
      </Box>
    </Box>
  );

  return (
    <Box sx={{ width: '100%' }}>

      <AppBar component="nav" elevation={0} sx={{ position: 'absolute', top: '0', left: '0', backgroundColor: 'transparent', py: 3 }}>

        <Toolbar variant="dense" sx={{ p: 0 }}>
          <Container
            maxWidth="xl"
            sx={{
              display: "flex",
              justifyContent: { xs: 'space-between', sm: 'space-between' },
              alignItems: "center",
            }}
          >

            <Link to={"/"} style={{ cursor: "pointer", width: '120px' }}>
              <img src={logo} alt="logo" style={{ cursor: "pointer", maxWidth: '120px' }} />
            </Link>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ display: { sm: 'none' } }}
            >
              <AiOutlineMenuFold style={{ color: '#fff' }} />
            </IconButton>
            <Box sx={{ display: { xs: 'none', sm: 'flex' }, justifyContent: 'space-between', alignItems: 'center', gap: 6, color: '#fff' }}>
              <Navbar />
              <Button component={Link} to='/' variant="contained" sx={{ ...ContButton }}>Contactez-nous</Button>
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{ 
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box component="main" >
        {children}
      </Box>
    </Box>
  );
}