import React, { useState } from 'react'
import { Box, Button, Paper, Typography, Step, StepLabel, Stepper } from '@mui/material'

import InformationsDuBienMain from './InformationsDuBien/InformationsDuBienMain';
import LoanInformationMain from './LoanInformation/LoanInformationMain';
import PersonalInformationsMain from './PersonalInformations/PersonalInformationsMain';
import OffreMain from './Offre/OffreMain';
import { FaArrowLeftLong } from 'react-icons/fa6'
import './FormStyle.css'
import { Link } from 'react-router-dom';


function getSteps() {
    return ['Informations du bien', 'Informations sur le prêt', 'Informations personnelles', 'Offre'];
}

function getStepContent(step) {
    switch (step) {
        case 0:
            return (
                <InformationsDuBienMain />
            );
        case 1:
            return (
                <LoanInformationMain />
            );
        case 2:
            return (
                <PersonalInformationsMain />
            );
        case 3:
            return (
                <OffreMain />
            );

        default:
            return "unknown step";
    }
}


export default function FormHead() {
    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    return (
        <Box sx={{ background: `linear-gradient(192deg, #16ABFF 11.34%, rgba(81, 248, 192, 0.77) 58.01%, rgba(65, 235, 245, 0.00) 90.74%)` }}>
            <Box sx={{ pt: 5, px: {xs: '2px', sm: '20px'},display: {xs: 'none', md: 'block'} }}>
                <Box textAlign='center' mb={6} position='relative'>
                    <Paper component={Link} to='/' elevation={1} sx={{ background: 'rgba(255, 255, 255, 0.15)', width: '45px', height: '45px', display: 'flex', justifyContent: 'center', alignItems: 'center',position:'absolute',top: '0', left:'0' }}>
                        <FaArrowLeftLong color='#ffffff' fontSize={22} />
                    </Paper>
                    <Box textAlign='center'>
                        <Typography variant='h2' sx={{ color: '#ffffff', mb: 1 }}>MEILLEURES MUTUELLES SANTÉ SÉNIOR 2023</Typography>
                        <Typography variant='body1' sx={{ color: '#ffffff' }}>Devis 100% gratuit et sans engagement</Typography>
                    </Box>
                </Box>
                <Stepper activeStep={activeStep} alternativeLabel>

                    {steps.map((step, index) => {
                        const labelProps = {};
                        const stepProps = {};
                        return (
                            <Step {...stepProps} key={index} >
                                <StepLabel sx={{ display: 'flex', alignItems: 'center' }} {...labelProps}>{step} </StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </Box>
            <Paper elevation={3} sx={{ position: 'relative', py: 8, px: {xs: 1, sm: 5}, borderRadius: '10px', width: {xs:'96%', sm:'75%'}, mt: 4, mx: 'auto' }} >

                {activeStep === steps.length ? (
                    <Paper
                        elevation={4}
                        sx={{ p: 2, borderRadius: '15px', mt: 4 }}
                    >
                        <Typography variant="h3" align="center" sx={{ py: 8 }}>
                            Thank You
                        </Typography>
                    </Paper>
                ) : (
                    <>
                        <form>{getStepContent(activeStep)}</form>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Button
                                variant="outlined"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ color: '#000' }}
                            >
                                Retour
                            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button variant='contained' color='primary' sx={{ px: 5 }} onClick={handleNext}>
                                {activeStep === steps.length - 1 ? "Finish" : "Suivant"}
                            </Button>
                        </Box>
                    </>
                )}
            </Paper>
        </Box >
    );
}
