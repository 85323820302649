import React from 'react'
import './LoadingStyle.css'
import { ReactComponent as Logos } from './favicon.svg'
export default function Loading() {
    
    return (
        <div id="spinner" className="container">
                <Logos />
                <div className="loading">

                </div>
        </div>

    )
}
