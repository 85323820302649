import React from 'react'
import { Button, Box, Stack, Typography, Paper, } from '@mui/material'

import { ReactComponent as MyBank } from './MyBank.svg'
import PromoCodeSponsorship from './PromoCodeSponsorship';
import AccountCreationMain from '../AccountCreation/AccountCreationMain';
import LaGarantieMain from './LaGarantie/LaGarantieMain';
import LoanFinallyEasyMain from './LoanFinallyEasy/LoanFinallyEasyMain';


export default function OffreMain() {

    return (
        <>
            <Box sx={{ px: { xs: '0', sm: 15 } }} >

                <Typography variant='h1' textAlign='center' sx={{ fontWeight: '700', mb: '10px' }}>Voilà l'offre recommandée pour vous manash</Typography>
                <Typography variant='body1' textAlign='center'>Vous pouvez personnaliser cette offre encore davantage afin de payer juste pour ce dont vous avez besoin.</Typography>

                <Paper elevation={3} sx={{ position: 'relative', mt: 5, py: 6, px: 5, textAlign: 'center', borderRadius: '10px', maxWidth: '500px', mx: 'auto' }} >
                    <Box sx={{ position: 'absolute', top: '10px', right: '10px', background: '#FDEECF', padding: '5px 15px', borderRadius: '100px', color: '#A88135' }}>
                        <Typography variant='body2' sx={{ fontWeight: '700' }}>Économisez 6 049 €</Typography>
                    </Box>

                    <Stack direction="column" spacing={1} justifyContent='center' alignItems='center'>
                        <MyBank width={55} height={55} style={{ color: '#0066ee' }} />
                        <Typography variant='h3' color='primary' sx={{ fontWeight: '700' }}>Assurance Emprunteur</Typography>
                    </Stack>
                    <Stack direction="row" spacing={1} justifyContent='center' alignItems='center' sx={{ py: 2 }}>
                        <Typography variant='h1' sx={{ fontSize: '60px', fontWeight: '700' }}>
                            5</Typography>
                        <Stack direction="column" justifyContent='center' alignItems='center' >
                            <Typography variant='h4' sx={{ fontWeight: '500' }}> ,96 €</Typography>
                            <Typography variant='h4' sx={{ fontWeight: '500' }}>/mois</Typography>
                        </Stack>

                    </Stack>
                    <Button variant='contained' fullWidth sx={{ py: 1.5 }}>Je continue ma souscription</Button>
                    <PromoCodeSponsorship />
                </Paper>
            </Box >
            <LaGarantieMain />
            <LoanFinallyEasyMain />
            <AccountCreationMain />
        </>
    )
}
