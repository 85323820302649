import React, { useState } from 'react'
import { Button, Box, Dialog, DialogContent, IconButton, Stack, Typography } from '@mui/material'

import { IoMdClose } from 'react-icons/io'
// import { ReactComponent as PromoOffece } from './PromoOffece.svg'


export default function PreviousSimulationDetected() {
    const [open, setOpen] = useState(false);
    const PromoOpen = () => {
        setOpen(true);
    };
    const PromoClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Button onClick={PromoOpen} variant="text" sx={{ mt: 2, textDecoration: 'underline' }} > Précédente simulation détectée</Button>
            <Dialog
                onClose={PromoClose}
                aria-labelledby="CONTACT"
                open={open}
                fullWidth
                maxWidth='xs'
                sx={{
                    '& .MuiDialog-paper': {
                        borderRadius: '20px',
                    },
                }}
            >
                <IconButton
                    aria-label="close"
                    onClick={PromoClose}
                    sx={{
                        position: 'absolute',
                        right: { xs: 0, sm: 15 },
                        top: { xs: 0, sm: 14 },
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <IoMdClose />
                </IconButton>
                <DialogContent  >

                    <Box sx={{ px: { xs: 0, sm: 1 }, py: 3, textAlign: 'center' }}>
                        <Stack direction="column" spacing={2} justifyContent='center' alignItems='center'>
                            {/* <PromoOffece width={55} height={55} style={{ color: '#0066ee' }} /> */}
                            <svg xmlns="http://www.w3.org/2000/svg" width={55} height={55} viewBox="0 0 24 24" ><path fill-rule="evenodd" clip-rule="evenodd" d="M5 2C3.34315 2 2 3.34315 2 5V19C2 20.6569 3.34315 22 5 22H19C20.6569 22 22 20.6569 22 19V5L19 2C18.4477 2 18 2.44772 18 3V6C18 7.10457 17.1046 8 16 8H8C6.89543 8 6 7.10457 6 6V3C6 2.44772 5.55228 2 5 2ZM16 15C16 17.2091 14.2091 19 12 19C9.79086 19 8 17.2091 8 15C8 12.7909 9.79086 11 12 11C14.2091 11 16 12.7909 16 15Z" fill="#27DAA6"></path><path d="M15 2C14.4477 2 14 2.44772 14 3V5C14 5.55228 14.4477 6 15 6C15.5523 6 16 5.55228 16 5V3C16 2.44772 15.5523 2 15 2Z" fill="#27DAA6"></path></svg>
                            <Typography variant='h3' >Précédente simulation détectée</Typography>
                        </Stack>
                        <Typography variant='body1' sx={{ pt: 1, mb: 6 }}>Heureux de vous revoir ici ! Nous avons sauvegardé les progrès réalisés lors de votre dernière visite, au cas où vous souhaiteriez reprendre plus tard. Vous pouvez aussi démarrer un nouveau devis.
                        </Typography>



                        <Stack direction="row" spacing={2} justifyContent='center' alignItems='center'>
                            <Button fullWidth variant="contained" sx={{ background: '#D6E3FD', color: '#0066ee' }}>Commencer un nouveau devis</Button>
                            <Button fullWidth variant="contained" color='primary'  >Reprendre où j'en étais</Button>
                        </Stack>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}
