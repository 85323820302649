import React from 'react'
import { Avatar, Box, Grid, Paper, Stack, Typography } from '@mui/material'
import Lucie from '../../../assets/Lucie.png'

import { ReactComponent as Smokeyes } from './Smokeyes.svg'
import { ReactComponent as SmokeNo } from './SmokeNo.svg'
import { FaCircleCheck } from 'react-icons/fa6'
import CurrentInsuranceMain from './CurrentInsurance/CurrentInsuranceMain'


export default function PersonalInformationsMain() {
    return (
        <>
            <Box sx={{px: {xs:'0', sm: 15}}}>

                <CurrentInsuranceMain />
            </Box>
            <Box py={10} sx={{px: {xs:'0', sm: 15}}}>

                <Stack direction="row" spacing={2} alignItems='center'>
                    <Avatar alt="Lucie" src={Lucie} />
                    <Typography variant='h4'>Lucie</Typography>
                </Stack>
                <Typography variant='h3' sx={{ fontWeight: '500', py: 4 }}>Enchantée manash. Est-ce que vous fumez ? On considère comme fumeurs les personnes ayant fumé ou vapoté au cours des deux dernières années.</Typography>
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Paper elevation={3} sx={{ position: 'relative', py: 8, px: 5, textAlign: 'center', borderRadius: '10px' }} >
                                <Box sx={{ position: 'absolute', top: '15px', right: '15px' }}>
                                    <FaCircleCheck style={{ fontSize: '25px', color: '#27DAA6' }} />
                                </Box>
                                <Smokeyes style={{ width: 75, height: 75 }} />
                                <Typography variant='h6' sx={{ fontWeight: '700', mt: '7px' }}>Oui, je fume</Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Paper elevation={3} sx={{ position: 'relative', py: 8, px: 5, textAlign: 'center', borderRadius: '10px' }} >
                                <Box sx={{ position: 'absolute', top: '15px', right: '15px' }}>
                                    <FaCircleCheck style={{ fontSize: '25px', color: '#27DAA6' }} />
                                </Box>
                                <SmokeNo style={{ width: 75, height: 75 }} />
                                <Typography variant='h6' sx={{ fontWeight: '700', mt: '7px' }}>Non, je ne fume pas</Typography>
                            </Paper>
                        </Grid>
                    </Grid>

                </Box>
            </Box>
        </>
    )
}
