import React from 'react'
import { Avatar, Box, FormControl, FormLabel, Grid, IconButton, InputAdornment, OutlinedInput, Stack, Typography } from '@mui/material'
import Lucie from '../../../assets/Lucie.png'

import CountryCode from '../LoanInformation/InformationsForm/CountryCode'
import { BsInfoCircle } from 'react-icons/bs'
import {FaRegEye} from 'react-icons/fa'
import {FaRegEyeSlash} from 'react-icons/fa'
export default function AccountCreationMain() {
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
  
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };
    return (
        <>
            <Box pt={10} >
                <Stack direction="row" spacing={2} alignItems='center'>
                    <Avatar alt="Lucie" src={Lucie} />
                    <Typography variant='h4'>Lucie</Typography>
                </Stack>
                <Typography variant='h3' sx={{ fontWeight: '500', py: 4 }}>Bienvenue chez 1swis, Mansni, nous sommes très heureux de vous compter parmi nous ! Vous pouvez dès à présent créer votre compte client pour accéder à tous vos services 1swis, à tout moment, depuis votre ordinateur ou notre application mobile.</Typography>

                <Box>


                    <Grid container spacing={4}>

                        <Grid item xs={12} md={6}>
                            <FormControl sx={{ textAlign: 'left' }} fullWidth>
                                <FormLabel sx={{ fontWeight: '500', color: '#000', mb: 1 }}>Adresse email*</FormLabel>
                                <OutlinedInput
                                    size="small"
                                    fullWidth
                                    placeholder="amelie.dupont@1swis.eu"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl sx={{ textAlign: 'left' }} fullWidth> 
                            <FormLabel sx={{ fontWeight: '500', color: '#000', mb: 1 }}>Mot de passe*</FormLabel>
                                <OutlinedInput 
                                  size="small"
                                  fullWidth
                                  placeholder="********"
                                    type={showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <FaRegEye /> : <FaRegEyeSlash />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                  
                                />
                            </FormControl>
 
                            <Stack direction="row" spacing={1} alignItems='star' justifyContent='left' mt={1}>
                                <Box sx={{ mt: '2px !important' }}>
                                    <BsInfoCircle style={{ color: '#96948a' }} />
                                </Box>
                                <Typography variant='body2' sx={{ textAlign: 'left', color: '#96948a', fontSize: '15px', fontWeight: '400' }}>Les mots de passe doivent comporter au moins 8 caractères et contenir au moins une majuscule, une minuscule et un symbole ou chiffre.</Typography>
                            </Stack>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormControl sx={{ textAlign: 'left' }} fullWidth>
                                <FormLabel sx={{ fontWeight: '500', color: '#000', mb: 1 }}>Téléphone*</FormLabel>
                                <Stack direction='row' spacing={1} justifyContent='space-around'>
                                    <CountryCode />
                                    <OutlinedInput
                                        size="small"
                                        fullWidth
                                        placeholder="6 12 34 56 78"
                                    />
                                </Stack>
                            </FormControl>
                            <Stack direction="row" spacing={1} alignItems='star' justifyContent='left' mt={1}>
                                <Box sx={{ mt: '2px !important' }}>
                                    <BsInfoCircle style={{ color: '#96948a' }} />
                                </Box>
                                <Typography variant='body2' sx={{ textAlign: 'left', color: '#96948a', fontSize: '15px', fontWeight: '400' }}>Assurez-vous qu'il s'agit du bon numéro car vous en aurez besoin pour télécharger votre certificat, gérer votre contrat et accéder à tous les services de l'application 1swis.</Typography>
                            </Stack>
                        </Grid>

                    </Grid>

                </Box>
            </Box >
        </>
    )
}
