import { Box, Button, Checkbox, Container, FormControlLabel, FormGroup, Paper, Stack, Typography } from '@mui/material'
import React from 'react'
import { ReactComponent as Logo } from '../../../assets/logo.svg'
import { AiOutlineDownload } from 'react-icons/ai'
import { BiSolidShield } from 'react-icons/bi'
import { BsFileEarmarkTextFill } from 'react-icons/bs'
import { FaArrowLeftLong, FaArrowRightLong } from 'react-icons/fa6'
 
export default function SignatureGuaranteesMain() {
    return (

        <Container maxWidth='xl'>

            <Box sx={{ maxWidth: { lg: '100%', xl: '55%' }, mx: 'auto' }}>
                <Box textAlign='center'>
                    <Logo />

                </Box>
                <Box textAlign='center' pt={8}>
                    <Typography variant='h1'>Assurez-vous d'avoir les bonnes garanties.</Typography>
                    <Typography variant='h6' sx={{ mt: 1 }}>Téléchargez et prenez connaissance du contenu des documents ci-dessous, relatifs à votre demande d'assurance emprunteur, pour vous assurer que vous disposez de la couverture exigée par votre banque.</Typography>
                </Box>
                <Box mt={4}>
                    <Paper elevation={1} sx={{ mt: 2, px: 4, py: 2, borderRadius: '10px' }}>
                        <Stack direction="row" spacing={2} justifyContent='space-between' alignItems='center'>
                            <Stack direction="row" spacing={2} justifyContent='space-around' alignItems='center'>
                                <BiSolidShield style={{ color: '#32deac', fontSize: '24px' }} />
                                <Typography variant='h5'>Conditions Générales</Typography>
                            </Stack>
                            <Button variant="contained" startIcon={<AiOutlineDownload />}>Télécharger</Button>
                        </Stack>
                    </Paper>
                    <Paper elevation={1} sx={{ mt: 2, px: 4, py: 2, borderRadius: '10px' }}>
                        <Stack direction="row" spacing={2} justifyContent='space-between' alignItems='center'>
                            <Stack direction="row" spacing={2} justifyContent='center' alignItems='center'>
                                <BsFileEarmarkTextFill style={{ color: '#32deac', fontSize: '24px' }} />
                                <Typography variant='h5'>Fiche standardisée d'information</Typography>
                            </Stack>
                            <Button variant="contained" startIcon={<AiOutlineDownload />}>Télécharger</Button>
                        </Stack>
                    </Paper>
                    <Stack direction="row" spacing={1} alignItems='star' justifyContent='left' sx={{ width: '100%', mt: 2, pr: 5, p: 2, borderRadius: '5px', background: '#fafafa' }}>
                        <FormGroup sx={{ width: '100%', }}>
                            <FormControlLabel fullWidth control={<Checkbox />} label="Je certifie avoir téléchargé et lu les documents ci-dessus." />
                        </FormGroup>
                    </Stack>
                    <Stack direction="row" spacing={2} justifyContent='space-between' alignItems='center' sx={{ mt: 6 }}>
                        <Button variant="contained" startIcon={<FaArrowLeftLong />} sx={{px:4}}>Précédent</Button>
                        <Button variant="contained" endIcon={<FaArrowRightLong />} disabled sx={{px:4}}>Suivant</Button>
                    </Stack>
                </Box>
            </Box>
        </Container>

    )
}
