import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { FaCheck } from "react-icons/fa6";

import { BsInfoCircle } from 'react-icons/bs'
import { AiOutlineClose } from "react-icons/ai";

export default function BackPsychologicalRisks() {
     
    return (
        <>
            <Box mt={2}>
                <Typography variant='h6' color='#96948a'>Sont notamment inclus :</Typography>
                <Stack direction="row" spacing={1.5} alignItems="start" sx={{ mt: 1 }}>
                    <FaCheck style={{ color: '#32deac', fontSize: '20px' }} />
                    <Typography variant='h5'>L'invalidité ou l'incapacité liée à des problèmes de dos sans conditions d’hospitalisation (lumbago ou lombalgie, sciatalgie ou cruralgie, névralgie,…)</Typography>
                </Stack>
                <Stack direction="row" spacing={1.5} alignItems="start" sx={{ mt: 2 }}>
                    <FaCheck style={{ color: '#32deac', fontSize: '20px' }} />
                    <Typography variant='h5'>L'invalidité ou l'incapacité liée à des troubles psychiatriques sans conditions d’hospitalisation (épuisement, le burn-out, syndrome de fatigue chronique, troubles anxiodépressifs,…)</Typography>
                </Stack>
            </Box>
             
            <Stack direction="row" spacing={1} alignItems='star' justifyContent='left' sx={{ mt: 4, pr: 5, backgroundColor: '#51f8c024', p: 2, borderRadius: '5px' }}>
                <Box sx={{ mt: '2px !important' }}>
                    <BsInfoCircle style={{ color: '#555554' }} />
                </Box>
                <Typography variant='body2' sx={{ textAlign: 'left', color: '#555554', fontSize: '15px', fontWeight: '400' }}>Aucune mesure de prévention particulière à l'application de cette garantie.</Typography>
            </Stack>
        </>
    )
}