import React from 'react'
import { Avatar, Box, Grid, Paper, Stack, Typography } from '@mui/material'
import Lucie from '../../../../assets/Lucie.png'

import { ReactComponent as CurrentLoan } from './CurrentLoan.svg'
import { ReactComponent as NewLoan } from './newLoan.svg'
import { FaCircleCheck } from 'react-icons/fa6'
export default function WantToDoType() {
    return (
        <>
            <Box>
                <Stack direction="row" spacing={2} alignItems='center'>
                    <Avatar alt="Lucie" src={Lucie} />
                    <Typography variant='h4'>Lucie</Typography>
                </Stack>
                <Typography variant='h3' sx={{ fontWeight: '500', py: 4 }}>Que souhaitez-vous faire?</Typography>
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Paper elevation={3} sx={{ position: 'relative', py: 8, px: 5, textAlign: 'center', borderRadius: '10px' }} >
                                <Box sx={{ position: 'absolute', top: '15px', right: '15px' }}>
                                    <FaCircleCheck style={{ fontSize: '25px', color: '#27DAA6' }} />
                                </Box>
                                <NewLoan style={{ width: 75, height: 75 }} />
                                <Typography variant='h6' sx={{ fontWeight: '700', mt: '7px' }}>Assurer un nouvel emprunt</Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Paper elevation={3} sx={{ position: 'relative', py: 8, px: 5, textAlign: 'center', borderRadius: '10px' }} >
                                <Box sx={{ position: 'absolute', top: '15px', right: '15px' }}>
                                    <FaCircleCheck style={{ fontSize: '25px', color: '#27DAA6' }} />
                                </Box>
                                <CurrentLoan style={{ width: 75, height: 75 }} />
                                <Typography variant='h6' sx={{ fontWeight: '700', mt: '7px' }}>Changer l’assurance d’un emprunt en cours</Typography>
                            </Paper>
                        </Grid>
                    </Grid>

                </Box>
            </Box>
 
            
        </>
    )
}
