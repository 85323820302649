import React from 'react'
import { Avatar, Box, FormControl,  FormLabel, Grid, InputAdornment,OutlinedInput, Paper, Stack, Typography } from '@mui/material'
import Lucie from '../../../../assets/Lucie.png'
import { BsInfoCircle } from 'react-icons/bs'
import { HiPlus } from 'react-icons/hi'
import CliquezIci from './CliquezIci'
export default function LoaninsureForm() {
  return (
    <>
      <Box py={10} >
        <Stack direction="row" spacing={2} alignItems='center'>
          <Avatar alt="Lucie" src={Lucie} />
          <Typography variant='h4'>Lucie</Typography>
        </Stack>
        <Typography variant='h3' sx={{ fontWeight: '500', py: 4 }}>À présent détaillons ensemble le prêt que vous souhaitez assurer.</Typography>

        <Box>
          <Paper elevation={3} sx={{ position: 'relative', py: 4, px: 4, textAlign: 'center', borderRadius: '10px' }} >
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <FormControl sx={{ textAlign: 'left' }} fullWidth>
                  <FormLabel sx={{ fontWeight: '500', color: '#000', mb: 1 }}>Montant initial*</FormLabel>
                  <OutlinedInput
                    size="small"
                    fullWidth
                    endAdornment={<InputAdornment position="end">€</InputAdornment>}
                    placeholder='200,000'
                    maxlength="100"
                    inputmode="numeric"
                    type="text"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl sx={{ textAlign: 'left' }} fullWidth>
                  <FormLabel sx={{ fontWeight: '500', color: '#000', mb: 1 }}>Taux*</FormLabel>
                  <OutlinedInput
                    size="small"
                    fullWidth
                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                    placeholder='1.00'
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl sx={{ textAlign: 'left' }} fullWidth>
                  <FormLabel sx={{ fontWeight: '500', color: '#000', mb: 1 }}>Première échéance*</FormLabel>
                  <OutlinedInput
                    size="small"
                    fullWidth
                    placeholder='MM/YYYY'
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl sx={{ textAlign: 'left' }} fullWidth>
                  <FormLabel sx={{ fontWeight: '500', color: '#000', mb: 1 }}>Durée totale*</FormLabel>
                  <OutlinedInput
                    size="small"
                    fullWidth
                    endAdornment={<InputAdornment position="end">mois</InputAdornment>}
                    placeholder='240'
                  />
                </FormControl>
                <Stack direction="row" spacing={1} alignItems='star' justifyContent='left' sx={{ mt: 1.2, pr: 5 }}>
                  <Box sx={{ mt: '4px !important' }}>
                    <BsInfoCircle style={{ color: '#96948a' }} />
                  </Box>
                  <Typography variant='body2' sx={{ textAlign: 'left', color: '#96948a', fontSize: '15px', fontWeight: '400' }}>Besoin d’aide pour traduire des années en mois ? <CliquezIci /></Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={12}>
                <Stack direction="row" spacing={1} alignItems='star' justifyContent='left' sx={{ mt: 1.5, pr: 5, backgroundColor: '#EAE9E3', p: 2, borderRadius: '5px' }}>
                  <Box sx={{ mt: '2px !important' }}>
                    <BsInfoCircle style={{ color: '#555554' }} />
                  </Box>
                  <Typography variant='body2' sx={{ textAlign: 'left', color: '#555554', fontSize: '15px', fontWeight: '400' }}>Si vous ne disposez pas des informations exactes, ne vous inquiétez pas ! Vous pouvez renseigner des valeurs approximatives et nos experts les corrigeront avec vous si nécessaire.</Typography>
                </Stack>

              </Grid>
            </Grid>
          </Paper>
        </Box>

        <Paper elevation={3} sx={{ position: 'relative', mt: 2, py: 4, px: 4, textAlign: 'center', borderRadius: '10px' }} >
          <Stack direction="row" spacing={2} alignItems='center'>
            <HiPlus fontSize={30} color='#96948a' />
            <Typography variant='h4' color='#96948a'>Renseigner un autre prêt</Typography>
          </Stack>
        </Paper>

      </Box >
    </>
  )
}
