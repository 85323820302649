import React from 'react'
import Slider from "react-slick";
import { ReactComponent as April } from './Logos/April.svg'
import { ReactComponent as Neoli } from './Logos/Neoli.svg'
import { ReactComponent as Solly } from './Logos/Solly.svg' 
import { ReactComponent as Alptis } from './Logos/Alptis.svg'
import Fma from './Logos/Fma.webp'
import SPVIE from './Logos/SPVIE.png'
// import WAZARI from './Logos/WAZARI.webp'
 


import { Box } from '@mui/material';
export default function PartnerSlider() {
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        cssEase: "linear",
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]

    };
    return (
        <div>

            <Slider {...settings}>
                <Box sx={{ border: '1px solid #eee', p: 4, borderRadius: '10px', width: '250px !important', }}>
                    <April style={{ maxWidth: '150px' }} />
                </Box>

                <Box sx={{ border: '1px solid #eee', p: 4, borderRadius: '10px', width: '250px !important', }}>
                    <Neoli style={{ maxWidth: '150px' }} />
                </Box>

                <Box sx={{ border: '1px solid #eee', p: 4, borderRadius: '10px', width: '250px !important', }}>
                    <Solly style={{ maxWidth: '150px' }} />
                </Box>

                <Box sx={{ border: '1px solid #eee', p: 4, borderRadius: '10px', width: '250px !important', }}>

                    <Box component='img' src={SPVIE} alt='SPVIE' sx={{ maxWidth: '150px' }} />
                </Box>

                <Box sx={{ border: '1px solid #eee', p: 4, borderRadius: '10px', width: '250px !important', }}>
                    <Box component='img' src={Fma} alt='SPVIE' sx={{ maxWidth: '150px' }} />
                </Box>

                {/* <Box sx={{ border: '1px solid #eee', p: 4, borderRadius: '10px', width: '250px !important', }}>
                 <Box component='img' src={WAZARI} alt='SPVIE' sx={{ maxWidth: '150px' }} />
                </Box> */}

                <Box sx={{ border: '1px solid #eee', p: 4, borderRadius: '10px', width: '250px !important', }}>
                    <Alptis style={{ maxWidth: '150px' }} />
                </Box>

            </Slider>
        </div>
    )
}
