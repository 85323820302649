import React from 'react'
import { Avatar, Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid,  MenuItem, OutlinedInput, Paper, Radio, RadioGroup, Select, Stack, Typography } from '@mui/material'
import Lucie from '../../../../assets/Lucie.png'
import { BsInfoCircle } from 'react-icons/bs'
 
import CountryCode from './CountryCode'


export default function InformationsFormMain() {
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };
    return (
        <>
            <Box pt={10} >
                <Stack direction="row" spacing={2} alignItems='center'>
                    <Avatar alt="Lucie" src={Lucie} />
                    <Typography variant='h4'>Lucie</Typography>
                </Stack>
                <Typography variant='h3' sx={{ fontWeight: '500', py: 4 }}>Parfait, merci ! Faisons mieux connaissance. Si nous nous connaissons déjà, vous pouvez vous connecter ici.</Typography>

                <Box>
                    <Paper elevation={3} sx={{ position: 'relative', py: 4, px: 4, borderRadius: '10px' }} >
                        <Box sx={{ mb: 2.5 }}>
                            <FormControl fullWidth>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    fullWidth
                                >
                                    <Grid container spacing={4}>
                                        <Grid item xs={12} md={6}>
                                            <FormControlLabel value="Monsieur" sx={{
                                                  py:1,
                                                px: 2, width: '100%', background: '#f9f9f9', borderRadius: '7px',
                                                '& .MuiSvgIcon-root': {
                                                    fontSize: 20,
                                                },
                                            }} control={<Radio />} label="Monsieur" />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControlLabel value="Madame" sx={{
                                                py:1,
                                                px: 2, width: '100%', background: '#f9f9f9', borderRadius: '7px',
                                                '& .MuiSvgIcon-root': {
                                                    fontSize: 20,
                                                },
                                            }} control={<Radio />} label="Madame" />
                                        </Grid>
                                    </Grid>
                                </RadioGroup>
                            </FormControl>
                        </Box>
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={6}>
                                <FormControl sx={{ textAlign: 'left' }} fullWidth>
                                    <FormLabel sx={{ fontWeight: '500', color: '#000', mb: 1 }}>Prénom*</FormLabel>
                                    <OutlinedInput
                                        size="small"
                                        fullWidth
                                        placeholder="Amélie"
                                        type="text"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl sx={{ textAlign: 'left' }} fullWidth>
                                    <FormLabel sx={{ fontWeight: '500', color: '#000', mb: 1 }}>Nom*</FormLabel>
                                    <OutlinedInput
                                        size="small"
                                        fullWidth
                                        placeholder="Dupont"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl sx={{ textAlign: 'left' }} fullWidth>
                                    <FormLabel sx={{ fontWeight: '500', color: '#000', mb: 1 }}>Date de naissance*</FormLabel>
                                    <OutlinedInput
                                        size="small"
                                        fullWidth
                                        placeholder="JJ/MM/AAAA"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl sx={{ textAlign: 'left' }} fullWidth>
                                    <FormLabel sx={{ fontWeight: '500', color: '#000', mb: 1 }}>Pays de naissance*</FormLabel>
                                    <OutlinedInput
                                        size="small"
                                        fullWidth
                                        placeholder="Australie"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl sx={{ textAlign: 'left' }} fullWidth>
                                    <FormLabel sx={{ fontWeight: '500', color: '#000', mb: 1 }}>Email*</FormLabel>
                                    <OutlinedInput
                                        size="small"
                                        fullWidth
                                        placeholder="amelie.dupont@luko.eu"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl sx={{ textAlign: 'left' }} fullWidth>
                                    <FormLabel sx={{ fontWeight: '500', color: '#000', mb: 1 }}>Téléphone*</FormLabel>
                                    <Stack direction='row' spacing={1} justifyContent='space-around'>
                                        <CountryCode />
                                        <OutlinedInput
                                            size="small"
                                            fullWidth
                                            placeholder="6 12 34 56 78"
                                        />
                                    </Stack>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <FormControl sx={{ textAlign: 'left' }} fullWidth>
                                    <FormLabel sx={{ fontWeight: '500', color: '#000', mb: 1 }}>Adresse actuelle*</FormLabel>
                                    <OutlinedInput
                                        size="small"
                                        fullWidth
                                        placeholder="12 rue des Écoles, 01000 Saint Denis les Bourgs"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <FormControl fullWidth>
                                    <Select
                                        value={age}
                                        onChange={handleChange}
                                        displayEmpty
                                        size='small'
                                        sx={{ textAlign: 'left' }}

                                    >
                                        <MenuItem value="" disabled><em>Sélectionnez votre catégorie professionnelle </em></MenuItem>
                                        <MenuItem value={10}>Cadre / Assimilé Cadre</MenuItem>
                                        <MenuItem value={20}>Employé autres</MenuItem>
                                        <MenuItem value={30}>Chef d'entreprise (hors BTP / Gros Œuvre)</MenuItem>
                                        <MenuItem value={40}>Fonctionnaire Catégorie A</MenuItem>
                                        <MenuItem value={50}>Agent de maîtrise</MenuItem>
                                        <MenuItem value={60}>Profession Libérale (hors médical/paramédical)</MenuItem>
                                        <MenuItem value={70}>Employé de bureau dont l'activité est purement administrative</MenuItem>
                                        <MenuItem value={80}>Fonctionnaires catégories B et C</MenuItem>
                                        <MenuItem value={90}>Sans profession</MenuItem>
                                        <MenuItem value={100}>Ouvrier</MenuItem>
                                        <MenuItem value={110}>Professions Paramédicales (infirmière / aide soignant / kiné…)</MenuItem>
                                        <MenuItem value={120}>Artisan</MenuItem>
                                        <MenuItem value={130}>Commerçant</MenuItem>
                                        <MenuItem value={140}>Policier / Pompier / Militaire</MenuItem>
                                        <MenuItem value={150}>Dentiste / Chirurgien / Interne / Vétérinaire / Sage Femme</MenuItem>
                                        <MenuItem value={160}>Chef d'entreprise BTP / Gros œuvre</MenuItem>
                                        <MenuItem value={170}>VRP / Chauffeur Taxi et VTC / Chauffeur routier</MenuItem>
                                        <MenuItem value={180}>Intérimaire / Saisonnier</MenuItem>
                                        <MenuItem value={190}>Profession agricole</MenuItem>
                                    </Select>
                                    <Stack direction="row" spacing={1} alignItems='star' justifyContent='left' mt={1}>
                                        <Box sx={{ mt: '2px !important' }}>
                                            <BsInfoCircle style={{ color: '#555554' }} />
                                        </Box>
                                        <Typography variant='body2' sx={{ textAlign: 'left', color: '#555554', fontSize: '15px', fontWeight: '400' }}>Nous avons besoin de ces informations pour vous offrir les mêmes garanties que votre banque.</Typography>
                                    </Stack>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Stack direction="row" spacing={1} alignItems='star' justifyContent='left' sx={{ width: '100%', mt: 1.5, pr: 5, p: 2, borderRadius: '5px', background: '#eae9e3' }}>
                                    <FormGroup sx={{ width: '100%' }}>
                                        <FormControlLabel fullWidth control={<Checkbox defaultChecked />} label="L'assuré est une personne morale." />
                                    </FormGroup>
                                </Stack>
                                <Stack direction="row" spacing={1} alignItems='star' justifyContent='left' sx={{ width: '100%', mt: 1.5, pr: 5, p: 2, borderRadius: '5px', background: '#eae9e3' }}>
                                    <FormGroup sx={{ width: '100%', textAlign: 'left' }}>
                                        <FormControlLabel fullWidth control={<Checkbox defaultChecked />} label="J'accepte que Luko m'envoie mon devis et des conseils pour protéger mon logement et faire des économies." />
                                    </FormGroup>
                                </Stack>

                            </Grid>
                        </Grid>
                    </Paper>
                </Box>
            </Box >
        </>
    )
}
