import React, { useState } from 'react'
import { Button, Box, Dialog, DialogContent, IconButton, Stack, Typography, List, ListItem, ListItemButton, ListItemIcon, ListItemText, } from '@mui/material'
 
import { IoMdClose } from 'react-icons/io'
import { ReactComponent as MyBank } from './MyBank.svg'
import { VscCircleFilled } from 'react-icons/vsc'

export default function CliquezIci() {

    const [open, setOpen] = useState(false);
    const CliquezOpen = () => {
        setOpen(true);
    };
    const CliquezClose = () => {
        setOpen(false);
    };


    const Items = ['10 ans = 120 mois', '15 ans = 180 mois', '20 ans = 240 mois', '25 ans = 300 mois']
    return (
        <>
            <Button onClick={CliquezOpen} sx={{
                color: '#96948a',
                textDecoration:'underline',
                p:'0',
                m:0,
                "&:hover": {
                    backgroundColor: "transparent"
                }
            }}> Cliquez ici</Button >

           <Dialog
                onClose={CliquezClose}
                aria-labelledby="CONTACT"
                open={open}
                fullWidth
                sx={{
                    '& .MuiDialog-paper': {
                        borderRadius: '20px'
                    },
                    '& .MuiPaper-root': {
                        margin: 0.5,
                        width: '98%'
                    }
                }}
            >
                <IconButton
                    aria-label="close"
                    onClick={CliquezClose}
                    sx={{
                        position: 'absolute',
                        right: { xs: 0, sm: 15 },
                        top: { xs: 0, sm: 14 },
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <IoMdClose />
                </IconButton>
                <DialogContent  >

                    <Box sx={{ px: { xs: 0, sm: 4 }, py: 4, textAlign: 'center' }}>
                        <Stack direction="column" spacing={2} justifyContent='center' alignItems='center'>
                            <MyBank width={55} height={55} style={{ color: '#0066ee' }} />
                            <Typography variant='h3' >Durée du prêt</Typography>
                        </Stack>
                        <Typography variant='body1' sx={{ pt: 3, pb: 2 }}>Vous trouverez ci-dessous les équivalences années et mois les plus courantes pour un prêt immobilier.</Typography>

                        <List
                            sx={{ width: '100%', maxWidth: '160px', m: '0 auto' }}
                            aria-label="contacts"
                        >
                            {
                                Items.map((item, index) => {
                                    return (
                                        <ListItem disableGutters key={index} sx={{ p: 0, textAlign: 'center' }}>
                                            <ListItemButton sx={{ p: 0 }}>
                                                <ListItemIcon sx={{ minWidth: '25px', p: 0 }}>
                                                    <VscCircleFilled />
                                                </ListItemIcon>
                                                <ListItemText sx={{ p: 0 }} primary={item} />
                                            </ListItemButton>
                                        </ListItem>

                                    )
                                })
                            }

                        </List>
                    </Box>


                </DialogContent>

            </Dialog>
        </>
    )
}
