import React from 'react'
import HeroMain from './HeroSection/HeroMain'
import Layout from '../../layout/Layout'
import OurPartnerMain from './OurPartner/OurPartnerMain'
 
import NousSommes from './NousSommes'
import Footer from '../../component/Footer'
import LesAvantagesMain from './LesAvantages/LesAvantagesMain'
export default function HomeMain() {
    return (
        <>
            <Layout>
                <HeroMain />
                <OurPartnerMain />
                <NousSommes />
                <LesAvantagesMain />
                <Footer />
            </Layout>
        </>
    )
}
