import { Box } from '@mui/material';
import React from 'react'
import { NavLink } from 'react-router-dom';
const style = {
    color: 'inherit',
    textDecoration: 'none',
    fontSize: '18px',
    fontWeight: '700',
    "&:hover": {
      color: '#32deac'
    },
  }
  
export default function Navbar() {
    const navItems = [
        {
            id: 1,
            Name: "Nos comparatifs",
            link: "/",
        },
        {
            id: 2,
            Name: "Notre vision",
            link: "/",
        },
        {
            id: 1,
            Name: "Témoignages",
            link: "/",
        },
    ];

    return (
        <>
            {navItems.map((item) => (
                <Box component={NavLink} key={item.Name} to={item.link} sx={{ ...style }}>
                    {item.Name}
                </Box>
            ))}
        </>
    )
}
