import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material'
import FormHead from './FormHead'
import LoadingScreen from '../Loading'
export default function RealEstateLoanHome() {

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => setLoading(false), 2000)
  }, [])


  return (
    <>
      {loading === false ? (
        <Box   bgcolor='#f4f3ef' height='100%' minHeight='100vh'>
          <FormHead />
        </Box>
      ) : (
        <LoadingScreen />
      )}
    </>
  )

      }