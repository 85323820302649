import React from 'react'
import { Avatar, Box, Grid, Paper, Stack, Typography } from '@mui/material'
import Lucie from '../../../../assets/Lucie.png'

import { ReactComponent as RentalInvestment } from './RentalInvestment.svg'
import { ReactComponent as Residence } from './Residence.svg'
import { ReactComponent as SecondHome } from './SecondHome.svg'


import { FaCircleCheck } from 'react-icons/fa6'

export default function PropertyFinancedLoan() {
    return (
        <>
            {/* Quel type de bien est financé par ce prêt immobilier ? */}
            <Box py={10} >
                <Stack direction="row" spacing={2} alignItems='center'>
                    <Avatar alt="Lucie" src={Lucie} />
                    <Typography variant='h4'>Lucie</Typography>
                </Stack>
                <Typography variant='h3' sx={{ fontWeight: '500', py: 4 }}>Quel type de bien est financé par ce prêt immobilier ?</Typography>
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Paper elevation={3} sx={{ position: 'relative', py: 8, px: 5, textAlign: 'center', borderRadius: '10px' }} >
                                <Box sx={{ position: 'absolute', top: '15px', right: '15px' }}>
                                    <FaCircleCheck style={{ fontSize: '25px', color: '#27DAA6' }} />
                                </Box>
                                <Residence style={{ width: 75, height: 75 }} />
                                <Typography variant='h6' sx={{ fontWeight: '700', mt: '7px' }}>Résidence principale</Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Paper elevation={3} sx={{ position: 'relative', py: 8, px: 5, textAlign: 'center', borderRadius: '10px' }} >
                                <Box sx={{ position: 'absolute', top: '15px', right: '15px' }}>
                                    <FaCircleCheck style={{ fontSize: '25px', color: '#27DAA6' }} />
                                </Box>
                                <SecondHome style={{ width: 75, height: 75 }} />
                                <Typography variant='h6' sx={{ fontWeight: '700', mt: '7px' }}>Résidence secondaire</Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Paper elevation={3} sx={{ position: 'relative', py: 8, px: 5, textAlign: 'center', borderRadius: '10px' }} >
                                <Box sx={{ position: 'absolute', top: '15px', right: '15px' }}>
                                    <FaCircleCheck style={{ fontSize: '25px', color: '#27DAA6' }} />
                                </Box>
                                <RentalInvestment style={{ width: 75, height: 75 }} />
                                <Typography variant='h6' sx={{ fontWeight: '700', mt: '7px' }}>Investissement locatif</Typography>
                            </Paper>
                        </Grid>
                    </Grid>

                </Box>
            </Box>
        </>
    )
}
