import React from 'react'
import { Avatar, Box, FormControl, FormLabel, Grid, OutlinedInput, Stack, Typography } from '@mui/material'
import Lucie from '../../../../assets/Lucie.png'

import CountryCode from '../InformationsForm/CountryCode'


export default function TwoBorrowersMain() {

    return (
        <>
            <Box pt={10} >
                <Stack direction="row" spacing={2} alignItems='center'>
                    <Avatar alt="Lucie" src={Lucie} />
                    <Typography variant='h4'>Lucie</Typography>
                </Stack>
                <Typography variant='h3' sx={{ fontWeight: '500', py: 4 }}>Malheureusement, nous ne couvrons pas encore plus de deux emprunteurs.</Typography>

                <Box>


                    <Grid container spacing={4}>

                        <Grid item xs={12} md={6}>
                            <FormControl sx={{ textAlign: 'left' }} fullWidth>
                                <FormLabel sx={{ fontWeight: '500', color: '#000', mb: 1 }}>Email*</FormLabel>
                                <OutlinedInput
                                    size="small"
                                    fullWidth
                                    placeholder="amelie.dupont@luko.eu"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl sx={{ textAlign: 'left' }} fullWidth>
                                <FormLabel sx={{ fontWeight: '500', color: '#000', mb: 1 }}>Téléphone*</FormLabel>
                                <Stack direction='row' spacing={1} justifyContent='space-around'>
                                    <CountryCode />
                                    <OutlinedInput
                                        size="small"
                                        fullWidth
                                        placeholder="6 12 34 56 78"
                                    />
                                </Stack>
                            </FormControl>
                        </Grid>

                    </Grid>

                </Box>
            </Box >
        </>
    )
}
