
import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { ReactComponent as SimpleComme } from './SimpleComme.svg'
import { ReactComponent as UnHumain } from './UnHumain.svg'
import { ReactComponent as OFrais } from './OFrais.svg'
import { ReactComponent as UnPrix } from './UnPrix.svg'
import { ReactComponent as HeadIcons } from './HeadIcons.svg'
export default function LoanFinallyEasyMain() {
    const AssurerData = [
        {
            Icons: <SimpleComme />,
            HeadText: "Simple comme bonjour",
            Des: 'Souscrivez et gérez votre contrat en ligne',
        },
        {
            Icons: <UnHumain />,
            HeadText: "Un humain, un vrai",
            Des: 'Des conseillers sont disponibles 7j/7 par tchat ou email',
        },
        {
            Icons: <OFrais />,
            HeadText: "0 frais cachés, 0 engagement",
            Des: 'Des contrats clairs, sans garanties inutiles et sans engagement',
        },
        {
            Icons: <UnPrix />,
            HeadText: "Un prix juste",
            Des: 'En moyenne, nos clients économisent 15 000€ pour les mêmes garanties',
        },

    ];

    return (
        <Box my={15}>
            <Box textAlign='center' sx={{ maxWidth: '75%', mx: 'auto' }}>
                <Box sx={{ width: '75px', height: '75px', borderRadius: '50%', background: '#32deac', p: 1.2,mx: 'auto',mb:2 }}>
                    <HeadIcons />
                    </Box>
                <Typography variant='h2'>Avec 1swis, assurer un prêt est enfin facile</Typography>
                <Typography variant='body1' sx={{ mt: 1.5 }}>L’assurance ne devrait pas décourager vos projets les plus fous. Chez 1swis, assurez votre prêt en quelques clics au juste prix, pour faire de vos rêves une réalité - sans efforts, sans compromis.</Typography>
            </Box>
            <Box mt={12} sx={{ maxWidth: '75%', mx: 'auto' }}>
                <Grid container spacing={8}>
                    {
                        AssurerData.map((Items, Index) => {
                            return (
                                <Grid item xs={6} md={6} key={Index} textAlign='center'>
                                    <Box sx={{ maxWidth: '50px', mx: 'auto', my: 1.5 }}>
                                        {Items.Icons}
                                    </Box>
                                    <Typography variant='h5' sx={{ fontWeight: '700' }}>{Items.HeadText}</Typography>
                                    <Typography variant='body1' color='#6b6651'>{Items.Des}</Typography>
                                </Grid>
                            )
                        })
                    }


                </Grid>
            </Box>
        </Box>
    )
}
