import React from 'react'
import { Avatar, Box, Grid, Paper, Stack, Typography } from '@mui/material'
import Lucie from '../../../../assets/Lucie.png'

import { ReactComponent as SingalPewson } from './SingalPewson.svg'
import { ReactComponent as CoBorrower } from './CoBorrower.svg'
import { ReactComponent as MorePeople } from './MorePeople.svg'


import { FaCircleCheck } from 'react-icons/fa6'


export default function PeopleWanToInsure() {
  return (
    <>
    {/* Combien de personnes voulez-vous assurer? */}
    <Box py={10} >
        <Stack direction="row" spacing={2} alignItems='center'>
            <Avatar alt="Lucie" src={Lucie} />
            <Typography variant='h4'>Lucie</Typography>
        </Stack>
        <Typography variant='h3' sx={{ fontWeight: '500', py: 4 }}>Combien de personnes voulez-vous assurer?</Typography>
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Paper elevation={3} sx={{ position: 'relative', py: 8, px: 5, textAlign: 'center', borderRadius: '10px' }} >
                        <Box sx={{ position: 'absolute', top: '15px', right: '15px' }}>
                            <FaCircleCheck style={{ fontSize: '25px', color: '#27DAA6' }} />
                        </Box>
                        <SingalPewson style={{ width: 75, height: 75 }} />
                        <Typography variant='h6' sx={{ fontWeight: '700', mt: '7px' }}>Une seule personne</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper elevation={3} sx={{ position: 'relative', pt: 8, pb:5, px: 5, textAlign: 'center', borderRadius: '10px' }} >
                        <Box sx={{ position: 'absolute', top: '15px', right: '15px' }}>
                            <FaCircleCheck style={{ fontSize: '25px', color: '#27DAA6' }} />
                        </Box>
                        <CoBorrower style={{ width: 75, height: 75 }} />
                        <Typography variant='h6' sx={{ fontWeight: '700', mt: '7px' }}>Vous et votre co-emprunteur</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper elevation={3} sx={{ position: 'relative', py: 8, px: 5, textAlign: 'center', borderRadius: '10px' }} >
                        <Box sx={{ position: 'absolute', top: '15px', right: '15px' }}>
                            <FaCircleCheck style={{ fontSize: '25px', color: '#27DAA6' }} />
                        </Box>
                        <MorePeople style={{ width: 75, height: 75 }} />
                        <Typography variant='h6' sx={{ fontWeight: '700', mt: '7px' }}>Plus de 2 personnes</Typography>
                    </Paper>
                </Grid>
            </Grid>

        </Box>
    </Box>
</>
  )
}
