import React from 'react'
import { Avatar, Box, Grid, Stack, TextField, Typography } from '@mui/material'
import Lucie from '../../../../assets/Lucie.png'

export default function MainResidence() {
    return (
        <>
            <Box py={10} >
                <Stack direction="row" spacing={2} alignItems='center'>
                    <Avatar alt="Lucie" src={Lucie} />
                    <Typography variant='h4'>Lucie</Typography>
                </Stack>
                <Typography variant='h3' sx={{ fontWeight: '500', py: 4 }}>Super ! Quelle est l’adresse de cette résidence principale ?</Typography>
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder="12 rue des Écoles, 01000 Saint Denis les Bourgs"
                                sx={{
                                    background: '#fff',
                                   
                                    '& ..MuiInput-root': {
                                        py: 2.5,
                                       
                                    },
                                    '& .MuiInputBase-input': {

                                        py: 2.5,
                                      
                                    }
                                }}
                            />
                        </Grid>

                    </Grid>

                </Box>
            </Box>
        </>
    )
}
