import React from 'react'
import { Container } from '@mui/material'
import { Box, styled, Typography } from "@mui/material";
import NousSommesImg from './NousSommesImg.svg'
 

export default function NousSommes() {
    const CustomBox = styled(Box)(({ theme }) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: theme.spacing(5), 
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            alignItems: "center",
            textAlign: "justify",

        },
    }));

    return (
        <Box  mb={12}>
              <Container maxWidth="xl">
                <Box>
                    <CustomBox>
                        <Box sx={{ flex: "1" }} textAlign='center'>
                            <img
                                src={NousSommesImg}
                                alt="NousSommesImg"
                                style={{ maxWidth: "100%" }}
                            />
                        </Box>
                        <Box sx={{ flex: "1.3" }}>
                            <Box my={4}>
                                <Typography variant='h1' sx={{
                                    mb: 2, color: '#37474F', fontWeight: '900', fontSize: {
                                        xs: '23px',
                                        sm: '25px',
                                        md: '30px',
                                        lg: '35px'
                                    },
                                }}>
                                    Nous sommes à vos côtés pour <span style={{ color: '#7EDBC0' }}>vous épauler dans votre choix.</span>
                                </Typography>
                            </Box>
                            <Typography variant='h3' sx={{ color: '#37474F', fontWeight: '900', mb:1.5 }}>Notre vision</Typography>
                            <Typography variant='h4' sx={{ color: '#37474F', fontWeight: '600' }}>Nos équipes simplifient pour vous les démarches et vous débarrassent de la lourdeur administrative.
                            </Typography>
                            <Box my={5}>
                                <Typography variant='h3' sx={{ color: '#37474F', fontWeight: '900', mb:1.5 }}>De l’efficacité</Typography>
                                <Typography variant='h4' sx={{ color: '#37474F', fontWeight: '600' }}>Vous pouvez souscrire à une assurance en quelques clics et dans la journée. </Typography>
                            </Box>

                            <Typography variant='h3' sx={{ color: '#37474F', fontWeight: '900', mb:1.5 }}>De l’humain</Typography>
                            <Typography variant='h4' sx={{ color: '#37474F', fontWeight: '600' }}>1Swis fait le choix de vous mettre en relation avec des professionnels continuellement formés et pleinement dédiés à votre suivi et à votre satisfaction.</Typography>

                        </Box>
                    </CustomBox>
                </Box>

            </Container>
        </Box>
    )
}
