
import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Switch, Typography } from '@mui/material'

import { BsInfoCircle } from 'react-icons/bs'
import Death from './CurrentInsuranceDetials/Death'
import { SlArrowDown } from "react-icons/sl";
import LossOfAutonomy from './CurrentInsuranceDetials/LossOfAutonomy';
import PermanentTotalDisability from './CurrentInsuranceDetials/PermanentTotalDisability';
import TotalTemporary from './CurrentInsuranceDetials/TotalTemporary';
import BackPsychologicalRisks from './CurrentInsuranceDetials/BackPsychologicalRisks';
import PermanentPartial from './CurrentInsuranceDetials/PermanentPartial';

export default function LaGarantieMain() {
  return (
    <>
      <Box my={15}>
        <Box textAlign='center' sx={{ maxWidth: '55%', mx: 'auto' }}>
          <Typography variant='h2'>La garantie d’être bien couvert</Typography>
          <Typography variant='body1' sx={{ mt: 1.5 }}>Sur la base de vos informations, nous avons déterminé les garanties que votre banque exige pour vous émettre un contrat avec la même couverture.</Typography>
        </Box>
        <Box mt={6}>
          <Typography variant='h2'>Votre assurance actuelle</Typography>
          <Stack direction="row" spacing={1} alignItems='star' justifyContent='left' sx={{ mt: 1.5, pr: 5, backgroundColor: '#51f8c024', p: 2, borderRadius: '5px' }}>
            <Box sx={{ mt: '2px !important' }}>
              <BsInfoCircle style={{ color: '#555554' }} />
            </Box>
            <Typography variant='body2' sx={{ textAlign: 'left', color: '#555554', fontSize: '15px', fontWeight: '400' }}>Les garanties minimales demandées par La Banque Postale que nous incluons par défaut dans votre contrat.</Typography>
          </Stack>
        </Box>
        <Accordion sx={{ my: 3, py: 2, borderRadius: '10px' }}>
          <AccordionSummary
            expandIcon={<SlArrowDown />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              '& .MuiAccordionSummary-content': {
                alignItems: 'center',
                justifyContent: 'space-between'
              }
            }}
          >
            <Typography variant='h4' sx={{ fontWeight: '700' }}>Décès </Typography>
            <Typography variant='h5' sx={{ fontWeight: '600', color: '#222222', mr: 5 }}>Requis par La Banque <br /> Postale</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Death />
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{ my: 3, py: 2, borderRadius: '10px' }}>
          <AccordionSummary
            expandIcon={<SlArrowDown />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            sx={{
              '& .MuiAccordionSummary-content': {
                alignItems: 'center',
                justifyContent: 'space-between'
              }
            }}
          >
            <Typography variant='h4' sx={{ fontWeight: '700' }}>Perte Totale et Irréversible d'Autonomie  </Typography>
            <Typography variant='h5' sx={{ fontWeight: '600', color: '#222222', mr: 5 }}>Requis par La Banque <br /> Postale</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <LossOfAutonomy />
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{ my: 3, py: 2, borderRadius: '10px' }}>
          <AccordionSummary
            expandIcon={<SlArrowDown />}
            aria-controls="panel3a-content"
            id="panel3a-header"
            sx={{
              '& .MuiAccordionSummary-content': {
                alignItems: 'center',
                justifyContent: 'space-between'
              }
            }}
          >
            <Typography variant='h4' sx={{ fontWeight: '700' }}>Invalidité Permanente et Totale</Typography>
            <Typography variant='h5' sx={{ fontWeight: '600', color: '#222222', mr: 5 }}>Requis par La Banque <br /> Postale</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <PermanentTotalDisability />
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{ my: 3, py: 2, borderRadius: '10px' }}>
          <AccordionSummary
            expandIcon={<SlArrowDown />}
            aria-controls="panel4a-content"
            id="panel4a-header"
            sx={{
              '& .MuiAccordionSummary-content': {
                alignItems: 'center',
                justifyContent: 'space-between'
              }
            }}
          >
            <Typography variant='h4' sx={{ fontWeight: '700' }}>Incapacité Temporaire Totale (franchise 90 jours) </Typography>
            <Typography variant='h5' sx={{ fontWeight: '600', color: '#222222', mr: 5 }}>Requis par La Banque <br /> Postale</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TotalTemporary />
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{ my: 3, py: 2, borderRadius: '10px' }}>
          <AccordionSummary
            expandIcon={<SlArrowDown />}
            aria-controls="panel5a-content"
            id="panel5a-header"
            sx={{
              '& .MuiAccordionSummary-content': {
                alignItems: 'center',
                justifyContent: 'space-between'
              }
            }}
          >
            <Typography variant='h4' sx={{ fontWeight: '700' }}>Risques Dos & Psychologiques </Typography>
            <Typography variant='h5' sx={{ fontWeight: '600', color: '#222222', mr: 5 }}>Requis par La Banque <br /> Postale</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <BackPsychologicalRisks />
          </AccordionDetails>
        </Accordion>

        <Box mt={6}>
          <Typography variant='h2'>Améliorez votre couverture</Typography>
          <Typography variant='h6'>Protégez-vous, ainsi que vos proches, contre les événements qui vous empêcheraient de rembourser votre prêt immobilier. L’ajout d’une ou plusieurs options pourra modifier quelque peu le prix initial de votre offre.</Typography>
        </Box>
        <Accordion sx={{ my: 3, py: 2, borderRadius: '10px' }}>
          <AccordionSummary
            expandIcon={<SlArrowDown />}
            aria-controls="panel6a-content"
            id="panel6a-header"
            sx={{
              '& .MuiAccordionSummary-content': {
                alignItems: 'center',
                justifyContent: 'space-between'
              }
            }}
          >
            <Typography variant='h4' sx={{ fontWeight: '700' }}>Invalidité Permanente Partielle </Typography>
            <Box sx={{ zIndex: 999 }}>
              <Switch defaultChecked />
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <PermanentPartial />
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  )
}
