
// import './App.css';
import React, { useState, useEffect } from 'react';
import WebRoutes from "./Routes/WebRoutes";
import LoadingScreen from "./pages/Loading";
import './App.css'
function App() {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000)
  }, [])



  return (
    <>
      {loading === false ? (
        <WebRoutes />
      ) : (
        <LoadingScreen />
      )}
    </>
  );
}

export default App;
