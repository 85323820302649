import React from "react";
import { Route, Routes } from "react-router-dom";
import HomeMain from '../pages/Home/HomeMain'
import FormHead from "../pages/RealEstateLoan/FormHead";
import RealEstateLoanHome from "../pages/RealEstateLoan/RealEstateLoanHome";
import SignatureGuaranteesMain from "../pages/RealEstateLoan/SignatureGuarantees/SignatureGuaranteesMain";
import PreviousSimulationDetected from "../pages/RealEstateLoan/PreviousSimulationDetected";
import ContactUs from "../pages/ContactPage/ContactUs";
 




export default function WebRoutes() {
  return (
    <>
      <Routes>
        <Route path="/" element={<HomeMain />} />
        <Route path="/formhead" element={<FormHead />} />
        <Route path="/Real_Estate" element={<RealEstateLoanHome />} />
        <Route path='/Signature' element={<SignatureGuaranteesMain />} />
        <Route path='/PreviousSimulationDetected' element={<PreviousSimulationDetected />} /> 

        {/* <Route path='/ContactUs' element={<ContactUs />} />  */}
        
      </Routes>
    </>
  )
}
