import React, { useState } from 'react'
import { Button, Box, Dialog, DialogContent, IconButton, Stack, Typography ,TextField, } from '@mui/material'

import { IoMdClose } from 'react-icons/io'
import { ReactComponent as PromoOffece } from './PromoOffece.svg'
 

export default function PromoCodeSponsorship() {
    const [open, setOpen] = useState(false);
    const PromoOpen = () => {
        setOpen(true);
    };
    const PromoClose = () => {
        setOpen(false);
    };

    const [isShowMore, setIsShowMore] = useState(false);

    const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore);
    }; 

    return (
        <>
            <Button onClick={PromoOpen} variant="text" sx={{ mt: 2,textDecoration:'underline' }} > Ajouter un code promo ou parrainage</Button>
            <Dialog
                onClose={PromoClose}
                aria-labelledby="CONTACT"
                open={open}
                fullWidth
                sx={{
                    '& .MuiDialog-paper': {
                        borderRadius: '20px',
                    },
                }}
            >
                <IconButton
                    aria-label="close"
                    onClick={PromoClose}
                    sx={{
                        position: 'absolute',
                        right: { xs: 0, sm: 15 },
                        top: { xs: 0, sm: 14 },
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <IoMdClose />
                </IconButton>
                <DialogContent  >

                    <Box sx={{ px: { xs: 0, sm: 4 }, py: 4, textAlign: 'center' }}>
                        <Stack direction="column" spacing={2} justifyContent='center' alignItems='center'>
                            <PromoOffece width={55} height={55} style={{ color: '#0066ee' }} />
                            <Typography variant='h3' >Ajouter un code promo ou de parrainage</Typography>
                        </Stack>
                        <Typography variant='body1' sx={{ pt: 1, mb: 6 }}>Un code promo est généralement court et en majuscules. <br />
                            Un code de parrainage est l'email de la personne qui vous parraine.
                        </Typography>

                        <TextField fullWidth id="outlined-basic" label="Code promo ou parrainage" variant="outlined" placeholder="LUKURIEUX" />

                        <Typography variant='body1' sx={{ px: 2, mt: 1, mb: 6, fontWeight: 600 }}>
                            Attention, dans certains cas, les réductions pourront être annulées lors de la résiliation de votre contrat. &nbsp;
                            {isShowMore && (
                                <Box>
                                    <br />
                                    Chez Luko, assurance est synonyme de confiance et de fidélité. Tous nos contrats sont sans engagement et nous vous proposons des services utiles et gratuits tels que la résiliation de votre ancien contrat et récompensons votre fidélité avec des offres de parrainage avantageuses pendant toute la durée de notre relation. <br />
                                    <br />
                                    En contrepartie, si vous décidez de nous quitter avant les 12 premiers mois de votre contrat, nous annulerons les réductions dont vous avez pu bénéficier à la souscription.
                                </Box>
                            )}
                            <span style={{ color: '#0066ee', textDecoration: 'underline', fontSize: '15px', fontWeight: '400', cursor: 'pointer' }} onClick={toggleReadMoreLess}>
                                {isShowMore ? "Masquer" : "En savoir plus"}
                            </span>
                        </Typography>

                        <Stack direction="row" spacing={2} justifyContent='center' alignItems='center'>
                            <Button fullWidth variant="contained" sx={{ background: '#EAE9E3', color: '#000' }}>Retour</Button>
                            <Button fullWidth variant="contained" disabled>Appliquer le code</Button>
                        </Stack>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}
