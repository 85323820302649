import React from 'react'
import Banktype from './BankPage/Banktype'
import LoaninsureForm from './LoanInsure/LoaninsureForm'
import PeopleWanToInsure from './PeopleWanToInsure/PeopleWanToInsure'
import InformationsFormMain from './InformationsForm/InformationsFormMain'
import { Box } from '@mui/material'
import TwoBorrowersMain from './TwoBorrowers/TwoBorrowersMain'

export default function LoanInformationMain() {
    return (
        <>
            <Box sx={{px: {xs:'0', sm: 15}}}>
                <Banktype />
                <LoaninsureForm />
                <PeopleWanToInsure />
                <InformationsFormMain />
                <TwoBorrowersMain />
            </Box>
        </>
    )
}
