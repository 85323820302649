import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { FaCheck } from "react-icons/fa6";

import { BsInfoCircle } from 'react-icons/bs'
import { AiOutlineClose } from "react-icons/ai";
export default function Death() {
    const NeSontData = [
        "Les accidents liés à la consommation d'alcool ou de stupéfiants",
        "Le suicide dans la première année suivant la souscription",
        "Les accidents résultant de vols aériens non autorisés",
        "L’invalidité suite à la participation volontaire à une émeute ou un acte de terrorisme",
        "La conduite en état d'ivresse",
        "Les homicides volontaires commis par un de vos co-assurés",
        "La pratique amateur d'un sport dangereux (sauf si elle est exceptionnelle et/ou encadrée par un professionnel)",
    ]
    return (
        <>
            <Box mt={2}>
                <Typography variant='h6' color='#96948a'>Sont notamment inclus :</Typography>
                <Stack direction="row" spacing={1.5} alignItems="center" sx={{ mt: 1 }}>
                    <FaCheck style={{ color: '#32deac', fontSize: '20px' }} />
                    <Typography variant='h5'>Les décès liés à un accident ou une maladie</Typography>
                </Stack>
            </Box>
            <Box sx={{ mt: 3 }}>
                <Typography variant='h6' color='#96948a'>Ne sont pas inclus :</Typography>
                {
                    NeSontData.map((Items, Index) => {
                        return (
                            <Stack direction="row" spacing={1.5} alignItems="center" key={Index} sx={{ my: 1 }}>
                                <AiOutlineClose style={{ color: '#D95762', fontSize: '20px' }} />
                                <Typography variant='h5'>{Items}</Typography>
                            </Stack>
                        )
                    })
                }

            </Box>
            <Stack direction="row" spacing={1} alignItems='star' justifyContent='left' sx={{ mt: 4, pr: 5, backgroundColor: '#51f8c024', p: 2, borderRadius: '5px' }}>
                <Box sx={{ mt: '2px !important' }}>
                    <BsInfoCircle style={{ color: '#555554' }} />
                </Box>
                <Typography variant='body2' sx={{ textAlign: 'left', color: '#555554', fontSize: '15px', fontWeight: '400' }}>Aucune mesure de prévention particulière à l'application de cette garantie.</Typography>
            </Stack>
        </>
    )
}
