import { createTheme } from '@mui/material/styles';

export let theme = createTheme({
  palette: {
    primary: {
      main: '#32deac',
    },
    secondary: {
      main: '#32deac',
    },
    text: {
      lightsec: '#7edbc0',

    },
  },
  typography: {
    fontFamily: `'Nunito', sans-serif`,
  },
});


theme = createTheme(theme, {
  components: {

    MuiTypography: {
      styleOverrides: {
        h1: {
          fontSize: '2.125rem',  //34px
          fontWeight: '700',
          color: '##2c2302',
        },
        h2: {
          fontSize: '1.5rem',  //24px
          fontWeight: '700',
          color: '##2c2302',
        },
        h3: {
          fontSize: '1.375rem',  //22px
          fontWeight: '700',
          color: '##2c2302',
        },
        h4: {
          fontSize: '1.25rem',  //20px
          fontWeight: '700',
          color: '##2c2302',
        },
        h5: {
          fontSize: '1.125rem',  // 18px
          fontWeight: '600',
          color: '#222222',
        },
        h6: {
          fontSize: '1rem',     //16px
          fontWeight: '600',
          color: '#222222',
        },
        body1: {
          fontSize: '1rem',  //16px
          fontWeight: '400',
          color: '#222222',
        },

        body2: {
          fontSize: '0.875rem',  //14px 
        },
        subtitle1: {
          fontSize: '.85rem',  //13.6px
          fontWeight: '400',
          color: '#222222',
        },
        subtitle2: {
          fontSize: '.75rem',  //12px
          fontWeight: '300',
          color: '#4d4a4a',
        },
      }
    },

    MuiButton: {
      styleOverrides: {
        text: {
          textTransform: 'inherit',
        },
        containedPrimary: {
          borderRadius: "6px",
          fontFamily: "'Montserrat', sans-serif",
          fontWeight: "600",
          textTransform: 'inherit',
          padding: '0.5rem 1rem',
          color: '#ffffff',
          '&:hover': {
            backgroundColor: '#20c788'
          }
        },
        outlinedPrimary: {
          borderRadius: "6px",
          fontFamily: "'Montserrat', sans-serif",
          fontWeight: "600",
          textTransform: 'inherit',
          padding: '0.4rem 1rem',
          color: '#ffffff',
          '&:hover': {
            backgroundColor: '#20c788',
            color: '#ffffff',
            borderColor: '#20c788'
          }
        },
      }
    },
    MuiContainer: {
      styleOverrides: {
          maxWidthXl: {
              [theme.breakpoints.up('lg')]: {
                 padding: '0 70px',
              },

              // Note that you can customize other properties here, like padding, color, .etc.
          }
      }
  }
  }
})




